.hero-text-block {
    max-width: 560px;
    margin: 0 auto 30px auto;
}

.icon-box-content {
    margin-bottom: 1rem;
    height: 100px;
    overflow-y: hidden;
}

.icon-box-content.expand {
    height: auto;
}

/** Header */

.header .inner-brand img.light-logo {
    display: none;
}

/** Dropdown Nav Styling **/
.inner-nav > ul > li.dropdown > a > .menu-item-span {
    transform: translateY(-50%);
    position: relative;
    display: block;
    top: 50%;
}

.dropdown-toggle {
    cursor: pointer;
}

.nav-link {
    letter-spacing: .05625rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .75rem;
}

.header.navbar {
    padding:0;
    /* padding: .5rem 1rem; */
}

.header > .brand-wrapper {
    position: relative;
    left: 0;
    padding-left: 15px;
}

.header > .navbar-brand {
    display: inline-block;
    vertical-align: middle;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 1.25rem;
}

@media screen and (min-width: 991.98px) {
    .header.header-transparent .navbar-brand img.dark-logo {
        display:none;
    }
    .header.header-transparent .navbar-brand img.light-logo {
        display:inline;
    }

    .header .navbar-brand img.light-logo {
        display:none;
    }

    .header.header-transparent.header-small .navbar-brand img.light-logo {
        display:none;
    }
    .header.header-transparent.header-small .navbar-brand img.dark-logo {
        display:inline;
    }
    
    .header.header-transparent.header-small .nav-link.active {
        color: #222;
    }

    .header.header-transparent .nav-link:hover {
        color: rgba(255, 255, 255, .7);
    }

    .header.header-transparent.header-small .nav-link {
        color: #222;
    }
    .header.header-transparent.header-small .nav-link:hover {
        color: rgba(34, 34, 34, .65);
    }

    .header.header-transparent .nav-link,
    .header.header-transparent .nav-link.active {
        color: #fff;
    }
    .header.header-transparent .nav-link:hover {
        color: rgba(255, 255, 255, .7);
    }

}

@media (max-width: 991.98px) {
    .header .navbar-brand img.dark-logo,
    .header.header-transparent .navbar-brand img.dark-logo {
        display:inline;
    }

    .header .navbar-brand img.light-logo,
    .header.header-transparent .navbar-brand img.light-logo {
        display:none;
    }

    .header .header-small .navbar-brand  img.light-logo,
    .header.header-transparent.header-small .navbar-brand  img.light-logo {
        display:none;
    } 

    .navbar-nav {
        background: #222;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-nav .nav-link {
        color: #fff !important;
        padding: 7.5px 0px 7.5px 15px;
        margin-bottom: 5px;
    }

    .dropdown-menu.show {
        margin-left:15px;
    }
}

@media screen and (max-width: 375px) {
    .nav.nav-tabs.nav-justified .nav-item .nav-link h6 {
        font-size: 12px;
    }

    #getInTouch .overlay-background {
        opacity: 0.8!important;
    }
}
